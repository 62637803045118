import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useHistory } from "react-router";
import { TbArrowsLeftRight } from "react-icons/tb";


import CustomTooltip from "../../components/CustomTooltip";
import InventoryMovement from "../../components/Modal/WMS/Inventory/Movement"
import InventoryMovementsReport from "../../components/Modal/WMS/Inventory/Report/movements"
import InventoryIncsOutsReport from "../../components/Modal/WMS/Inventory/Report/incomingsOutgoings"
import InventoryStockReport from "../../components/Modal/WMS/Inventory/Report/reportStock"
import InventoryHistory from "../../components/Modal/WMS/Inventory/History"
import InventoryProductLocation from "../../components/Modal/WMS/Inventory/ProductLocation"
import InventoryLots from "../../components/Modal/WMS/Inventory/Lots"
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from '../../components/Grid';

import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import { PermissionContext } from '../../hooks/PermissionContext';

import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import { MovementTypesEnum, InventoryReportTypesEnum } from "../../utils/enumsUtil";
import { setLocationType } from "react-geocode";
import ProductLocation from "../../components/Modal/WMS/Inventory/ProductLocation";

const Inventory = ({
    reduxGetClients,
    shippers,
    reduxGetInventory,
    inventory,
    reduxGetWarehouses,
    warehouses,
    reduxResetLocation,
    reduxResetLots,
    reduxDownloadInventory
}) => {
    const {
        handleSubmit: searchSubmit,
        control: searchControl,
        formState: { errors: searchErrors },
        watch: searchWatch,
        setValue: searchSetValue,
    } = useForm({
        defaultValues: {
            shipper: null,
            warehouses: null,
        }
    });

    const history = useHistory();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;
    const { session } = useAuth();
    const { hasPermission } = useContext(PermissionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');
    const [movementInfo, setMovementInfo] = useState();
    const [showMovementModal, setShowMovementModal] = useState();
    const [showDownloadMovementsReportModal, setShowDownloadMovementsReportModal] = useState(false);
    const [showDownloadIncsOutsReportModal, setShowDownloadIncsOutsReportModal] = useState(false);
    const [showDownloadStockReportModal, setShowDownloadStockReportModal] = useState(false);
    const [historyInfo, setHistoryInfo] = useState();
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [lotsInfo, setLotsInfo] = useState();
    const [showLotsModal, setShowLotsModal] = useState(false);
    const [downloadInventoryReport] = useState(false);
    const [locationInfo, setLocationInfo] = useState();
    const [showLocationModal, setShowLocationModal] = useState(false);


    const shipperWatcher = searchWatch('shipper', null);
    const warehousesWatcher = searchWatch('warehouses', null);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) 
    };

    useEffect(() => {
        reduxGetClients();
    }, [])
    useEffect(() => {
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });
    }, [])

    useEffect(() => {
        if (!shippers || Object.keys(shippers).length === 0) return;

        if (hasPermission("feature:isClient")) {
            const shipperSelected = shippers.items.find(shipper => shipper.idCompany === session.role.idCompany);
            if (shipperSelected) {
                searchSetValue("shipper", { value: shipperSelected.idCompany, label: shipperSelected.description });
            } else {
                console.warn('No shipper found matching the idCompany from session.role');
            }
        }
    }, [shippers, session])


    const performSearch = () => {
        reduxGetInventory({
            page: 1,
            offset: offset,
            search: search,
            shipper: shipperWatcher ? shipperWatcher.value : null,
            warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
        })
    }

    const handleOpenUploadMovement = (type, description) => {
        const movementInfoObj = {
            type,
            description,
        }

        setMovementInfo(movementInfoObj);
        setShowMovementModal(true);
    }

    const handleOpenHistoryMovement = (idInventory) => {
        const historyInfoObj = {
            idInventory
        };

        setHistoryInfo(historyInfoObj);
        setShowHistoryModal(true);
    };

    const handleOpenInventoryLots = (idProduct, idWarehouse) => {

        const lotsInfo = {
            idProduct, idWarehouse
        };

        setLotsInfo(lotsInfo);
        setShowLotsModal(true);
    };

    const handleCloseUploadMovement = () => {
        setShowMovementModal(false);
    }

    const handleCloseHistory = () => {
        setShowHistoryModal(false);
    };

    const handleCloseLots = () => {
        setShowLotsModal(false);
        reduxResetLots();
        reduxGetInventory({
            page: 1,
            offset: offset,
            search: search,
            shipper: shipperWatcher ? shipperWatcher.value : null,
            warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
        })
    };

    const handleOpenInventoryLocation = (idProduct, idWarehouse) => {

        const locationInfo = {
            idProduct, 
            idWarehouse
        };

        setLocationInfo(locationInfo);
        setShowLocationModal(true);
    };

    const handleCloseLocation = () => {
        setShowLocationModal(false);
        reduxResetLots();
        reduxGetInventory({
            page: 1,
            offset: offset,
            search: search,
            shipper: shipperWatcher ? shipperWatcher.value : null,
            warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
        })
    };

    const handleOpenDownloadReport = (reportType) => {
        switch (reportType) {
            case InventoryReportTypesEnum.MOVEMENTS:
                setShowDownloadMovementsReportModal(true)
                break;
            case InventoryReportTypesEnum.INCSOUTS:
                setShowDownloadIncsOutsReportModal(true)
                break;
            case InventoryReportTypesEnum.STOCK:
                setShowDownloadStockReportModal(true)
                break;
            default:
                break;
        }
    }

    const handleCloseDownloadReport = (reportType) => {
        switch (reportType) {
            case InventoryReportTypesEnum.MOVEMENTS:
                setShowDownloadMovementsReportModal(false)
                break;
            case InventoryReportTypesEnum.INCSOUTS:
                setShowDownloadIncsOutsReportModal(false)
                break;
            case InventoryReportTypesEnum.STOCK:
                setShowDownloadStockReportModal(false)
                break;
            default:
                break;
        }
    }

    const handleDownloadInventoryReport=() => {
        reduxDownloadInventory({
            search: search,
            shipper: shipperWatcher ? shipperWatcher.value : null,
            warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
        })
    }

    const actionButtons = [
        {
            permission: 'feature:wms-inventory-incomings-module-show',
            onClick: () => history.push("/wms/incomings"),
            description: "Procesar Recepciones",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.FileImport
        },
        {
            permission: 'feature:show-relocation',
            onClick: () => history.push("/wms/relocation"),
            description: "Procesar traslados de inventario",
            buttonType: buttonTypes.Danger,
            buttonIcon: buttonIcon.FileExport
        },
        {
            permission: 'feature:wms-inventory-generalreport-download',
            onClick: () => { handleOpenDownloadReport(InventoryReportTypesEnum.MOVEMENTS) },
            description: "Descargar reporte general de movimientos",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.DownloadFile
        },
        {
            permission: 'feature:wms-inventory-stockreport-download',
            onClick: () => { handleOpenDownloadReport(InventoryReportTypesEnum.STOCK) },
            description: "Descargar reporte stock",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Clipboard
        },
        {
            permission: 'feature:wms-inventory-incs_outs-report-download',
            onClick: () => { handleOpenDownloadReport(InventoryReportTypesEnum.INCSOUTS) },
            description: "Descargar reporte de ingresos y salidas",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.FileContract
        },

    ];
/*
    const columns = [
        {
            title: "ID Producto",
            render: (rowData) => {
                return <span>{rowData.product.idProduct}</span>;
            },
            field: "product.idProduct",
            searchable: true,
        },
        {
            title: "SKU",
            render: (rowData) => {
                return <span>{rowData.product.sku}</span>;
            },
            field: "product.sku",
            searchable: true,
        },
        {
            title: "Nombre de producto",
            render: (rowData) => {
                return <span>{rowData.product.name}</span>;
            },
            field: "product.name",
            searchable: true,
        },
        {
            title: "Abreviatura",
            render: (rowData) => {
                return <span>{rowData.product.abbreviation}</span>;
            },
            field: "product.abbreviation",
            searchable: true,
        },
        {
            title: "Stock",
            render: (rowData) => {
                return <span>{rowData.stock}</span>;
            },
        },
        {
            title: "Bodega",
            render: (rowData) => {
                return <span>{`${rowData.subWarehouse?.name || rowData.subWarehouse?.warehouseCode || ''}`}</span>;
            },
            field: "idWarehouse",
        },
        {
            title: "Ubicación",
            render: (rowData) => {
                if (rowData.wmsProductLocations && rowData.wmsProductLocations.length > 0) {
                    const formatLocation = (e) => {
                        const fields = [e.col, e.stowage, e.rack, e.level];
                        return fields
                            .filter(field => field !== null && field !== undefined && field !== "")
                            .join(", ");
                    };
            
                    return (
                        <span>
                            <CustomTooltip data={rowData.wmsProductLocations.map(formatLocation)} />
                        </span>
                    );
                } else {
                    return <span> </span>;
                }
            },
            field: "wmsProductLocations.idLocation",
        },
        {
            title: "Remitente",
            render: (rowData) => {
                return <span>{rowData.product.company.description}</span>;
            },
        },
        {
            title: "Opciones",
            render: (rowData) => {
                return (
                    <>
                            <Show>
                                <button
                                    title="Detalles de movimiento"
                                    className="btn btn-primary btn-sm  btn-circle mr-2"
                                    type="button"
                                    onClick={() => handleOpenHistoryMovement(rowData.idInventory)}
                                >
                                    <i className="fa fa-list fa-lg"></i>
                                </button>
                            </Show>
                            {
                            <Show when='feature:wms-lots-option-show'>
                                <button
                                    title='Inventario por Lotes'
                                    className="btn btn-success btn-sm  btn-circle mr-2"
                                    type="button"
                                    onClick={() => handleOpenInventoryLots(rowData.idInventory, rowData.idWarehouse, rowData.product.idCompany)}
                                >
                                    <i className='fa fa-th-large'></i>
                                </button>
                            </Show> }
                    </>
                );
            },
        },
    ];
    */

    const columns = [
        {
            title: "ID Producto",
            render: (rowData) => {
                return <span>{rowData.idProduct}</span>;
            },
            field: "product.idProduct",
            searchable: true,
        },
        {
            title: "SKU",
            render: (rowData) => {
                return <span>{rowData.sku}</span>;
            },
            field: "product.sku",
            searchable: true,
        },
        {
            title: "Nombre de producto",
            render: (rowData) => {
                return <span>{rowData.name}</span>;
            },
            field: "product.name",
            searchable: true,
        },
        {
            title: "Abreviatura",
            render: (rowData) => {
                return <span>{rowData.abbreviation}</span>;
            },
            field: "product.abbreviation",
            searchable: true,
        },
        {
            title: "Stock Disponible",
            render: (rowData) => {
                return <span>{rowData.availableStock}</span>;
            },
        },
        {
            title: "Stock Caducado",
            render: (rowData) => {
                return <span>{rowData.expiredStock}</span>;
            },
        },
        {
            title: "Stock Averiado",
            render: (rowData) => {
                return <span>{rowData.damagedStock}</span>;
            },
        },
        {   
            visible: hasPermission('feature:inventory-show-fullinfo'),
            title: "Stock Sobrante",
            render: (rowData) => {
                return <span>{rowData.surplusStock}</span>;
            },
        },
        {
            visible: hasPermission('feature:inventory-show-fullinfo'),
            title: "Stock Faltante",
            render: (rowData) => {
                return <span>{rowData.missingStock}</span>;
            },
        },
        {
            visible: hasPermission('feature:inventory-show-fullinfo'),
            title: "Stock Suplantación",
            render: (rowData) => {
                return <span>{rowData.supplantingStock}</span>;
            },
        },
        {
            title: "Bodega",
            render: (rowData) => {
                return <span>{rowData.warehouse}</span>;
            },
        },
        {
            title: "Ubicación",
            render: (rowData) => {
                if (rowData.locations && rowData.locations.length > 0) {
                    return (
                        <span>
                            <CustomTooltip data={rowData.locations} />
                        </span>
                    );
                } else {
                    return <span> </span>;
                }
            },
            field: "locations",
        },
        {
            title: "Remitente",
            render: (rowData) => {
                return <span>{rowData.company}</span>;
            },
        },
        {
            title: "Opciones",
            render: (rowData) => {
                return (
                    <div className="d-flex justify-content-center">
                        { <Show when='feature:wms-lots-option-show'>
                            <button
                                title='Inventario por Lotes'
                                className="btn btn-success btn-sm btn-circle mr-2"
                                type="button"
                                onClick={() => handleOpenInventoryLots(rowData.idProduct, rowData.idWarehouse)}
                            >
                                <i className='fa fa-th-large'></i>
                            </button>
                        </Show> }
                        { <Show when='feature:wms-location-option-show'>
                            <button
                                title='Ubicación en Bodega'
                                className="btn btn-primary btn-sm btn-circle"
                                type="button"
                                onClick={() => handleOpenInventoryLocation(rowData.idProduct, rowData.idWarehouse)}
                            >
                                <i className='fa fa-map-marker'></i>
                            </button>
                        </Show> }
                    </div>
                );
            },
        }
    ];

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Inventario"} subtitle={"Módulo para la gestión del Inventario de Bodegas"} actionButtons={actionButtons} />
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Listado de Inventario de Bodegas
                            </h6>
                        </div>
                        <div className="card-body">
                            <form onSubmit={searchSubmit(performSearch)}>
                                <div className="d-flex">
                                    <Show whenNot={["feature:isClient"]}>
                                        <div className='form-group w-25 mr-2'>
                                            <label
                                                htmlFor='shipper'
                                                className='form-label'>
                                                Remitente
                                            </label>
                                            <Controller
                                                control={searchControl}
                                                name="shipper"
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            styles={customStyles}
                                                            options={
                                                                shippers && Object.keys(shippers).length > 0
                                                                    ? shippers.items
                                                                        .filter(e => e.isActive === true && e.country == countryUser && countryUser)
                                                                        .map((ele, key) => ({
                                                                            value: ele.idCompany,
                                                                            label: ele.description,
                                                                        }))
                                                                    : []
                                                            }
                                                        />
                                                        {
                                                            searchErrors.shipper &&
                                                            <span className="error-message">
                                                                {searchErrors.shipper.message}
                                                            </span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Show>

                                    <div className='form-group w-25 mr-2'>
                                        <label
                                            htmlFor='warehouses'
                                            className='form-label'>
                                            Bodega
                                        </label>
                                        <Controller
                                            control={searchControl}
                                            name="warehouses"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        isMulti
                                                        isClearable
                                                        styles={customStyles}
                                                        options={
                                                            warehouses && warehouses.items
                                                                ? warehouses.items
                                                                    .filter(e => e.isActive === true && e.warehouseHubs[0]?.hub?.idCountry == countryUser && countryUser)
                                                                    .map(ele => ({
                                                                        value: ele.idWarehouse,
                                                                        label: ele.name || ele.warehouseCode,
                                                                    }))
                                                                : []
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <Button
                                            variant='primary'
                                            style={{ marginTop: '32px' }}
                                            type='submit'
                                            id='btnSearch'>
                                            Buscar
                                        </Button>
                                        <Button  
                                            onClick={ ()=>{ handleDownloadInventoryReport() } }                                          
                                            variant='primary'
                                            style={{ marginTop: '32px', marginLeft: '10px' }}
                                            type='button'
                                            id='btnDownload'>
                                            <i class="fas fa-file-download"></i> Descargar
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            <Grid
                                cols={columns}
                                data={
                                    inventory && Object.keys(inventory).length > 0
                                        ? inventory.items
                                        : []
                                }
                                page={
                                    inventory && Object.keys(inventory).length > 0
                                        ? Number(inventory.page)
                                        : 1
                                }
                                pages={
                                    inventory && Object.keys(inventory).length > 0
                                        ? Number(inventory.totalPages)
                                        : 0
                                }
                                total={
                                    inventory && inventory.hasOwnProperty("total") ? inventory.total : 0
                                }
                                offset={offset}
                                onChangePage={(page) => {
                                    setCurrentPage(page);
                                    reduxGetInventory({
                                      page: page,
                                      offset: offset,
                                      search: search,
                                      shipper: shipperWatcher ? shipperWatcher.value : null,
                                      warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
                                    });
                                }}
                                onChangeRange={(value) => {
                                    setOffset(value);
                                    reduxGetInventory({
                                        page: 1,
                                        offset: value,
                                        search: search,
                                        shipper: shipperWatcher ? shipperWatcher.value : null,
                                        warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
                                    });
                                }}
                                defaultValue={search}
                                onChangeSearch={(value) => {
                                    setSearch(value);
                                    reduxGetInventory({
                                        page: 1,
                                        offset: offset,
                                        search: value,
                                        shipper: shipperWatcher ? shipperWatcher.value : null,
                                        warehouses: warehousesWatcher ? warehousesWatcher.map(e => e.value).join(",") : null,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <InventoryMovement
                show={showMovementModal}
                handleClose={handleCloseUploadMovement}
                movementInfo={movementInfo}
            />
            <InventoryMovementsReport
                show={showDownloadMovementsReportModal}
                handleClose={() => { handleCloseDownloadReport(InventoryReportTypesEnum.MOVEMENTS) }}
            />
            <InventoryIncsOutsReport
                show={showDownloadIncsOutsReportModal}
                handleClose={() => { handleCloseDownloadReport(InventoryReportTypesEnum.INCSOUTS) }}
            />
            <InventoryStockReport
                show={showDownloadStockReportModal}
                handleClose={() => { handleCloseDownloadReport(InventoryReportTypesEnum.STOCK) }}
            />
            <InventoryHistory
                show={showHistoryModal}
                handleClose={handleCloseHistory}
                historyInfo={historyInfo}
            />
            <InventoryLots
                show={showLotsModal}
                handleClose={handleCloseLots}
                lotsInfo={lotsInfo}
            />
            <ProductLocation
                show={showLocationModal}
                handleClose={handleCloseLocation}
                locationInfo={locationInfo}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
        inventory: state.inventoryState.inventory,
        warehouses: state.warehouseState.warehouses,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetInventory: (payload) =>
            dispatch({
                type: "READ_INVENTORY_REQUEST",
                value: payload,
            }),
        reduxResetInventory: () =>
            dispatch({
                type: "RESET_WAREHOUSE_INVENTORY_FORM",
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxResetLocation: () =>
            dispatch({
                type: "RESET_PRODUCTLOCATION_FORM",
            }),
        reduxResetLots: () => dispatch({ type: "RESET_LOTS_FORM" }),
        reduxDownloadInventory: (payload) =>
            dispatch({
                type: "DOWNLOAD_INVENTORY_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);