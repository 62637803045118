import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import moment from 'moment';

import { buttonIcon, buttonTypes } from "../../../../utils/buttonsUtil";

import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import Header from "../../../../components/Page/header";
import Grid from '../../../../components/Grid';
import CollapsedSection from "../../../../components/CollapsedSection";
import NumberInput from "../../../../components/Inputs/Text/Number";
import MultiFile from "../../../../components/Inputs/MultiFile";
import ScanGuide from "../../../../components/Modal/WMS/Inventory/Incomings/Returns/scanGuide";
import { TableWithInputs } from "../../../../components/TableWithInputs";

const Return = ({
    wmsOrderForReturn,
    reduxResetWMSOrderForReturn,
    reduxCreateManifest,
    successfulCreateManifest,
    errorsCreateManifest,
    createManifestResult,
    reduxResetManifestForm,
    reduxGetHub,
    hubData,
}) => {
    const {
        handleSubmit: handleSubmitDetail,
        control: detailControl,
        formState: { errors: detailErrors },
        setError: setDetailError,
        reset: resetDetail,
    } = useForm({
        defaultValues: {
            manifestDetail: [],
        }
    });

    const {
        handleSubmit: handleAddEvidences,
        control: evidencesControl,
        formState: { errors: evidencesError },
        setValue: setEvidencesValue,
        getValues: getEvidencesValues,
        watch: evidencesWatch,
        reset: resetEvidences,
    } = useForm({
        defaultValues: {
            files: [],
            comments: '',
        }
    });

    const history = useHistory();
    const userData = JSON.parse(window.localStorage.getItem('userData'));

    const [activeSectionKey, setActiveSectionKey] = useState("manifestDetail");
    const [manifestDetail, setManifestDetail] = useState(null);
    const [showTrackingIdModal, setShowTrackingIdModal] = useState(true);

    const filesWatcher = evidencesWatch('files', [])

    const handleToggleSection = (sectionKey) => {
        setActiveSectionKey(prevState => (prevState === sectionKey ? null : sectionKey))
    }

    useEffect(() => {
        handleReset();
        if (!userData.idHub) {
            Swal.fire(
                "No permitido",
                `Lo sentimos pero su usuario no está asociado a ningún HUB o el HUB no tiene asociada una bodega`,
                "error"
            );
            history.push("/wms/incomings")
            return;
        }

        reduxGetHub({
            id: userData.idHub
        });
    }, [])

    useEffect(() => {
        if (successfulCreateManifest) {
            Swal.fire(
                "Proceso Exitoso",
                `${createManifestResult.message}`,
                "success"
            );
            handleReset();
        }
        if (errorsCreateManifest) {
            Swal.fire(
                "Proceso Fallido",
                `${createManifestResult.message}`,
                "error"
            );
        }

    }, [successfulCreateManifest, errorsCreateManifest, createManifestResult])

    const submitDetail = (data) => {
        let dataFormatted = [];

        for (const item of data.manifestDetail) {
            const totalQty = Number(item.available) + Number(item.missing) + Number(item.damaged) + Number(item.extra) + Number(item.supplanted) + Number(item.expired);

            if (totalQty > item.quantity || totalQty < item.quantity) {
                Swal.fire(
                    "Validación fallida",
                    `Se ha encontrado una totalidad diferente a la declarada para los productos, se esperaba: ${item.quantity} y se recibió ${totalQty}`,
                    "error"
                );
                return;
            }

            let rowDetail = {
                product: {
                    idProduct: item.idProduct,
                },
                ...item,
            }
            delete rowDetail["idProduct"];
            delete rowDetail["quantity"];

            dataFormatted.push(rowDetail)
        }

        setManifestDetail(dataFormatted)
        handleToggleSection("manifestEvidences")
    }

    const addEvidences = () => {
        handleSubmitManifest();
    }

    const handleChangeFiles = (e) => {
        setEvidencesValue('files', Array.from(e.target.files))
    }

    const handleRemoveFile = (index) => {
        const newFiles = filesWatcher.filter((_, i) => i !== index);
        setEvidencesValue('files', newFiles)
    }

    const handleSubmitManifest = () => {
        const data = {
            idShipper: wmsOrderForReturn.order.idCompany,
            idAddress: wmsOrderForReturn.order.idAddress,
            idWarehouse: hubData?.hubWarehouses[0]?.idWarehouse,
            manifestType: 2,
            declaredQty: wmsOrderForReturn.declaredQty,
            manifestDetail,
            files: getEvidencesValues("files"),
            comments: getEvidencesValues("comments"),
        }

        reduxCreateManifest(data);
    }

    const handleCloseTrackingIdModal = () => {
        setShowTrackingIdModal(false);
        if (!wmsOrderForReturn) history.push("/wms/incomings")
    }

    const handleReset = () => {
        reduxResetManifestForm();
        reduxResetWMSOrderForReturn();
        resetDetail();
        resetEvidences();
        setManifestDetail([]);
        activeSectionKey != "manifestDetail" && handleToggleSection("manifestDetail")
        setShowTrackingIdModal(true);
    }

    const actionButtons = [
        {
            onClick: () => {
                handleReset();
                history.push("/wms/incomings");
            },
            description: "Regresar a Ingresos",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
    ];

    const columns = [
        {
            header: "Producto",
            field: "idProduct",
            render: (data, field) => (
                <>
                    <span>{data.product.name}</span>
                    <input
                        {...field}
                        hidden
                        value={data.product.idProduct}
                    />
                </>
            )
        },
        {
            header: "Declarada",
            field: "quantity",
            render: (data, field) => (
                <NumberInput
                    {...field}
                    readOnly
                    value={data.quantity}
                />
            )
        },
        {
            header: "Disponible",
            field: "available",
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        },
        {
            header: "Caducada",
            field: 'expired',
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        },
        {
            header: "Averiada",
            field: 'damaged',
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        },
        {
            header: "Faltante",
            field: 'missing',
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        },
        {
            header: "Sobrante",
            field: 'extra',
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        },
        {
            header: "Suplantación",
            field: 'supplanted',
            render: (_, field) => (
                <NumberInput
                    {...field}
                    defaultValue={0}
                />
            )
        }
    ]

    return (
        <>
            <div>
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <Header title={"Procesar Devolución"} actionButtons={actionButtons} />
                            </div>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        Manifiesto
                                    </h6>
                                </div>
                                <div className="card-body d-flex flex-column flex-gap-1">
                                    <CollapsedSection
                                        sectionKey="manifestDetail"
                                        title="Detalle"
                                        show={activeSectionKey === 'manifestDetail'}
                                        onToggle={() => handleToggleSection("manifestDetail")}
                                    >
                                        <div className="col-auto m-0 p-0">
                                            <form onSubmit={handleSubmitDetail(submitDetail)}>
                                                <Controller
                                                    control={detailControl}
                                                    name='manifestDetail'
                                                    render={({ field }) => (
                                                        <>
                                                            <TableWithInputs
                                                                inputControlName={"manifestDetail"}
                                                                columns={columns}
                                                                data={wmsOrderForReturn?.order?.orderDetails}
                                                                control={detailControl}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <div className='d-flex justify-content-center'>
                                                    <Button
                                                        variant='primary'
                                                        tabIndex={15}
                                                        type='submit'>
                                                        Confirmar
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </CollapsedSection>
                                    <CollapsedSection
                                        sectionKey="manifestEvidences"
                                        title="Evidencias y Observaciones"
                                        show={activeSectionKey === 'manifestEvidences'}
                                        onToggle={() => handleToggleSection("manifestEvidences")}
                                    >
                                        <form onSubmit={handleAddEvidences(addEvidences)}>
                                            <div className="d-flex flex-column flex-gap-1">
                                                <div className='form-group'>
                                                    <Controller
                                                        control={evidencesControl}
                                                        name="files"
                                                        render={() => (
                                                            <>
                                                                <label
                                                                    htmlFor='files'
                                                                    className='form-label'>
                                                                    Evidencias
                                                                </label>
                                                                <MultiFile
                                                                    files={filesWatcher}
                                                                    tabIndex={16}
                                                                    accept="image/*"
                                                                    onChange={handleChangeFiles}
                                                                    handleRemove={handleRemoveFile}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <Controller
                                                        control={evidencesControl}
                                                        name="comments"
                                                        rules={{
                                                            required: 'El campo comentario es requerido'
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <label
                                                                    htmlFor='comments'
                                                                    className='form-label'>
                                                                    Comentarios/Observaciones
                                                                </label>
                                                                <textarea
                                                                    {...field}
                                                                    className={`form-control`}
                                                                    type="textarea"
                                                                    tabIndex={17}
                                                                />
                                                                {
                                                                    evidencesError.comments &&
                                                                    <span className="error-message">
                                                                        {evidencesError.comments.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <Button
                                                        variant='primary'
                                                        tabIndex={18}
                                                        type='submit'>
                                                        Confirmar
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CollapsedSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScanGuide show={showTrackingIdModal} handleClose={handleCloseTrackingIdModal} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        wmsOrderForReturn: state.wmsState.wmsOrderForReturn,
        successfulCreateManifest: state.wmsState.successfulCreateManifest,
        errorsCreateManifest: state.wmsState.errorsCreateManifest,
        createManifestResult: state.wmsState.createManifestResult,
        hubData: state.branchState.rowEdited,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxCreateManifest: (payload) =>
            dispatch({
                type: "CREATE_MANIFEST_REQUEST",
                value: payload,
            }),
        reduxResetManifestForm: () =>
            dispatch({
                type: "RESET_MANIFEST_FORM",
            }),
        reduxResetWMSOrderForReturn: () =>
            dispatch({
                type: "RESET_WMS_ORDER_FOR_RETURN"
            }),
        reduxGetHub: (payload) =>
            dispatch({
                type: "READ_BRANCH_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Return);